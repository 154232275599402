export const MOCK_DATA_ENABLED =
  process.env.NEXT_PUBLIC_MOCK_ENABLED === "true";
export const API_ORIGIN = process.env.NEXT_PUBLIC_API_ORIGIN as string;
export const API_ORIGIN_2 = process.env.NEXT_PUBLIC_API_ORIGIN_2 as string;

export const API_VERSION_0 = "/api/v0";
export const API_VERSION_1 = "/api/v1";

export const METABASE_URL = "https://metabase.implentio.com/embed/dashboard";
export const FULLSTORY_ORG_ID = process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID || "";
export const SENTRY_ORG_SLUG = process.env.NEXT_PUBLIC_SENTRY_ORG_SLUG || "";
export const DEPLOY_STAGE = process.env.NEXT_PUBLIC_DEPLOY_STAGE || "";
